import React from "react";
import Layout from "../components/layout";
import SEO from "../components/SEO";
import HTML from "../components/HTML";
import FindDealer from "../components/findDealer/findDealer";
import Jumbotron from "../components/jumbotron/Jumbotron";
import WrappedFindDealerMobile from "../components/findDealer/wrappedFindDealerMobile";

function DealerLocator(props) {
  const { pageContext } = props;
  const { pageContent, dealerInfo, googleAPIkey } = pageContext;
  const dealerLocals = props.pageContext.dealers;

  let components = [
    <SEO key={"SEO"} title={"Contact"} data={pageContent.SEO} />,
    <Jumbotron key={"Jumbotron"} data={{ ...pageContent.Jumbotron }} />,
    <WrappedFindDealerMobile
      data={{ order: 1, visible: true }}
      dealerInfo={dealerInfo}
      pageContent={pageContent}
      pageContext={pageContext}
    />,
    <FindDealer
      data={{ ...pageContent.DealerLocator }}
      dealerInfo={{ ...dealerInfo }}
      dealerLocals={dealerLocals}
      googleAPIkey={googleAPIkey}
      key={"DealerLocator"}
      isDealerLocator={true}
    />,
  ];

  if (pageContent.hasOwnProperty("ArrayRawHTML")) {
    const { arrayRawHTML } = pageContent.ArrayRawHTML;
    const rawComponents = arrayRawHTML.map((element) => {
      return (
        <HTML
          data={{
            order: Number(element.order),
            visible: element.visible,
            rawHTML: element.rawHtml,
          }}
          key={"HTML"}
        />
      );
    });
    components.push(...rawComponents);
  }

  let sortedComponents = components.sort((a, b) =>
    parseFloat(a.props.data.order) - parseFloat(b.props.data.order)
  ); // sorts the components based on json data
  // filters out the components that are not visible
  let visibleComponents = sortedComponents.filter((component) => {
    return component.props.data.visible === true;
  });

  return (
    <Layout>
      {visibleComponents}
    </Layout>
  );
}

export default DealerLocator;
